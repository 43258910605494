
export const API_URL = "https://ghi-report-api.mystrand.org/api"
//export const API_URL = "http://localhost:8000/api"

// export const APP_ENV = "client"
export const APP_ENV = "reviewer"

export const X_API_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJuYW1lIjoiQVBJIFRlc3QgVXNlciIsImVtYWlsIjoiYXBpdGVzdHVzZXJAc3RyYW5kbHMuY29tIiwiaWF0IjoxNjk3MDI2MTQzfQ._-UfDVbZgF7RG0iUY39xe6YqsH77TK6JSokLi_Q7VpQ';

export const GET_SINGLE_REPORT_URL = (APP_ENV === "client") ?
    '/zoho/getSingleReport' :
    '/zoho/getSingleDraftReport';


const isUserClient = () => {

    return (APP_ENV === "client") ? true : false;
}

export default {
    isUserClient
}

export const ENV = "production"
